<template>
  <div v-if="enableCheckbox">
    <input
      id="form__complianceZbozi"
      v-model="complianceZbozi"
      class="form-check-input"
      name="complianceZbozi"
      type="checkbox">
    <label
      class="form-check-label"
      for="form__complianceZbozi"
      v-html="$options.filters.prepositions($t('globals.checkboxes.complianceZbozi'))"/>
  </div>
</template>

<script>
export default {
  computed: {
    complianceZbozi: {
      get() {
        return this.$store.state.cart.input.complianceZbozi
      },
      set(value) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'complianceZbozi', value: value })
      },
    },
    enableCheckbox() {
      return (
        this.$store.state.globals.settings.integrations.seznam_shop_id &&
        this.$store.state.globals.settings.integrations.seznam_shop_id.length > 0
      )
    },
  },
}
</script>
